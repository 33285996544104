import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';
import { QuickNavComponent } from './quick-nav/quick-nav.component';

@NgModule({ declarations: [QuickNavComponent],
    exports: [QuickNavComponent], imports: [CommonModule, TranslateModule, RouterModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {}
